/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-05-17",
    versionChannel: "nightly",
    buildDate: "2023-05-17T00:20:26.307Z",
    commitHash: "75569e1311807be865ad1d0c60d5f3b151632421",
};
